:root {
  --gradient-color-1: #ffccbc;
  --gradient-color-2: #ffccbc;
  --gradient-color-3: #ffccbc;
  --gradient-color-4: #ffccbc;
}

.nav-container {
  height: auto;
  background-color: inherit;
  z-index: 1000;
}

.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.hero-text-container {
  padding-top: calc(96px + 3rem); /* 96px is the initial negative margin-top in the Hero component */
}

.process-step-titles {
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: .5rem;
}
.responsive-titles {
  flex-wrap: wrap;
  font-size: .5rem;
}

@media (max-width: 768px) {
  .process-step-title,
  .process-step-title-overlay {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .process-step-title,
  .process-step-title-overlay {
    font-size: 1rem;
  }
}

.process-step-container {
  margin-top: -16px;
  position: relative;
}

.process-step-title,
.process-step-title-overlay {
  font-size: 3.4rem;
  font-weight: 900;
  margin: 0;
  line-height: 1.1em;
  letter-spacing: -1px;
}

.process-step-title-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  padding-right: 30px;
  height: 100%;
  opacity: 1;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 3.4rem;
  font-weight: 900;
  margin: 0;
  line-height: 1.1em;
  letter-spacing: -1px;
}

.process-step-1 .process-step-title-overlay {
  background-image: linear-gradient(90deg, var(--gradient-color-1), var(--gradient-color-2));
  animation: animated-gradient-title-1 8s infinite;
}

.process-step-2 .process-step-title-overlay {
  background-image: linear-gradient(90deg, var(--gradient-color-2), var(--gradient-color-3));
  animation: animated-gradient-title-2 8s infinite;
}

.process-step-3 .process-step-title-overlay {
  background-image: linear-gradient(90deg, var(--gradient-color-3), var(--gradient-color-1));
  animation: animated-gradient-title-3 8s infinite;
}

@keyframes animated-gradient-title-1 {
  0%,
  16.667%,
  100% {
    opacity: 1;
  }

  33.333%,
  83.333% {
    opacity: 0;
  }
}

@keyframes animated-gradient-title-2 {
  0%,
  16.667%,
  66.667%,
  100% {
    opacity: 0;
  }

  33.333%,
  50% {
    opacity: 1;
  }
}

@keyframes animated-gradient-title-3 {
  0%,
  50%,
  100% {
    opacity: 0;
  }

  66.667%,
  83.333% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .faq-bg-pattern {
    padding-top: 60px; /* Adjust this value to add space on top of the FAQ text */
  }
}

@media (max-width: 767px) {
  .hero-text-container {
    padding-top: calc(96px + 4rem); /* Adjust this value to add more spacing */
  }
}

.herop {
  background: linear-gradient(-70deg, #ffecdd, #ffffff);
  -webkit-background-clip:  text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;
}

.background-layer {
  position: absolute;
  z-index: -1;
}

.background-layer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(221, 221, 221, 0.6); /* Adjust the background color and opacity as needed */
}

.hero {
  position: relative;
  z-index: 0;
}

.hero::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('https://www.transparenttextures.com/patterns/gray-floral.png');
  opacity: .2; /* adjust this value as needed */
  z-index: -1;
}

.faq {
  position: relative;
  z-index: 0;
}

.faq::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('https://www.transparenttextures.com/patterns/flowers.png');
  opacity: .3; /* adjust this value as needed */
  z-index: -1;
}